<div class="banner-box">
    <img src="../../assets/opleidingen.jpg" alt="">
    <div class="titel-box">Opleidingen</div>
</div>
<mat-tab-group #tabs2 mat-align-tabs="start">
    <mat-tab>
        <mat-accordion>
            <mat-expansion-panel (closed)="open = false" [ngClass]="{'hide': !firstOpen}" #panel>
                <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title>{{panel.expanded ? 'Klik hier om terug te gaan naar alle opleidingen' : 'Klik hier om opleidingdetails te zien'}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="selectedOpleiding != undefined" id="details" class="opleiding-details">
                    <table>
                        <tr>
                            <td><h2> {{selectedOpleiding.naam}} </h2></td>
                            <td>  
                                <div class="duur-label">Loopduur: {{selectedOpleiding.duur}}</div>
                            </td>
                        </tr>
                    </table>
                    <p> {{selectedOpleiding.beschrijving}} </p>
                    <div class="break-line"></div>
                    <p class="sub-title no-margin">Toelatingseisen:</p>
                    <p>Van de student die start wordt verwacht dat hij of zij: </p>
                    <table style="margin-left: 20px;">
                        <tr *ngFor="let eis of selectedOpleiding.toelatingseisen">
                            <td><mat-icon>check_circle</mat-icon></td>
                            <td [innerHTML]="eis"></td>
                        </tr>
                        <tr *ngIf="!eis">
                            <td><mat-icon>check_circle</mat-icon></td>
                            <td>
                                <p> zich houdt aan de regels die zijn gesteld door Alhidajah. Zie <a routerLink="/regels-en-afspraken" routerLinkActive="active">Regels & Afspraken</a></p>
                            </td>
                        </tr>
                    </table>
                    <div class="break-line"></div>
                    <div *ngIf="selectedOpleiding.onderdelen">
                        <p class="sub-title">Onderdelen:</p>
                        <table style="margin-left: 20px;">
                            <tr *ngFor="let i of selectedOpleiding.onderdelen">
                                <td><mat-icon>check_circle</mat-icon></td>
                                <td>
                                    <p>{{i}}</p> 
                                </td>
                            </tr>
                        </table>
                        <div class="break-line"></div>
                    </div>
                    <p class="sub-title">Literatuur:</p>
                    <table style="margin-left: 20px;">
                        <tr *ngFor="let l of selectedOpleiding.literatuur">
                            <td><mat-icon>menu_book</mat-icon></td>
                            <td><p>{{l}}</p></td>
                        </tr>
                    </table>
                    <div class="break-line"></div>
                    <p class="sub-title">Toetsing:</p>
                    <table style="margin-left: 20px;">
                        <tr *ngFor="let t of selectedOpleiding.toetsing">
                            <td><mat-icon>school</mat-icon></td>
                            <td>
                                <p>{{t}} </p> </td>
                        </tr>
                    </table>
                    <div class="break-line"></div>
                    <p class="sub-title no-margin">Leerdoelen:</p>
                    <p class="no-margin">Na afronding van deze cursus beheerst de student de volgende leerdoelen. </p>
                    <p>{{selectedOpleiding.leerdoelenIntro}} </p>
                    <table style="margin-left: 20px;">
                        <tr *ngFor="let ld of selectedOpleiding.leerdoelen">
                            <td><mat-icon>how_to_reg</mat-icon></td>
                            <td>
                                <p>{{ld}}</p> 
                            </td>
                        </tr>
                    </table>
                    <div *ngIf="selectedOpleiding.doorstroommogelijkheden">
                        <div class="break-line"></div>
                        <p class="sub-title">Doorstroommogelijkeden:</p>
                        <table style="margin-left: 20px;">
                            <tr>
                                <td><mat-icon>stairs</mat-icon></td>
                                <td>
                                    <p>{{selectedOpleiding.doorstroommogelijkheden}}</p> 
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="break-line"></div>
                    <p class="sub-title">Investeringen:</p>
                    <table style="margin-left: 20px;">
                        <tr><td><mat-icon>schedule</mat-icon></td>
                            <td>
                                <p>{{selectedOpleiding.aantalLessen}} lessen</p> 
                            </td>
                        </tr>
                        <tr>
                            <td><mat-icon>schedule</mat-icon></td>
                            <td>
                                <p>{{selectedOpleiding.tijdsInvestering}}</p> 
                            </td>
                        </tr>
                        <tr><td><mat-icon>euro_symbol</mat-icon></td>
                            <td>
                                <p>{{selectedOpleiding.kosten}}</p>
                            </td>
                        </tr>
                    </table>
                    <button class="schrijf-in-button" (click)="setIndexSelectedTab(1)">Naar inschrijfmogelijkheden!</button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-tab>
    <mat-tab>
        <button (click)="setIndexSelectedTab(0)" mat-icon-button><mat-icon>arrow_back</mat-icon></button>
        <app-docenten-widget></app-docenten-widget>
    </mat-tab>
  </mat-tab-group>
<div class="opleidingen">
    <p class="opleiding-titel">{{this.pageData?.titel}}</p>
    <p class="opleiding-subtitel"> {{this.pageData?.subtitel}}</p>

    <div class="opleidingen-box animate__animated animate__zoomIn">
        <div [ngStyle]="{'background-image' : 'url(' + o.afbeelding + ')'}"  *ngFor="let o of opleidingen" (click)="getDetails(o)" class="opleiding">
            <div class="opleiding-naam-box">
                <span>{{o.naam}}</span>
            </div>
        </div>
    </div>
</div>
