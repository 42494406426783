<div class="banner-box">
    <img src="../../assets/regelsenafspraken.jpg" alt="">
    <div class="titel-box">REGELS & AFSPRAKEN</div>
</div>
<div class="intro-box">
    <p class="intro"> {{regelsdata?.intro}} </p>
</div>
<div class="algemene-voorwaarden">
    <button class="animate__animated animate__zoomInRight" color="primary" (click)="openAlgemeneVoorwaarden()" mat-raised-button>Zie ook onze algemene voorwaarden</button>
</div>
<div class="container">
    <div class="box">
        <br>
        <p class="intro">De regels & afspraken:</p>
        <ul>
            <li *ngFor="let x of regelsdata?.regelsenafspraken"> {{x}} </li>   
        </ul>    
    </div>
</div>