import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { IdentiteitComponent } from './identiteit/identiteit.component';
import { BestuurComponent } from './bestuur/bestuur.component';
import { OrganisatieComponent } from './organisatie/organisatie.component';
import { OpleidingenComponent } from './opleidingen/opleidingen.component';
import { DocentenComponent } from './docenten/docenten.component';
import { StudentenComponent } from './studenten/studenten.component';
import { RegelsenafsprakenComponent } from './regelsenafspraken/regelsenafspraken.component';
import { PraktischComponent } from './praktisch/praktisch.component';
import { JaarkalenderComponent } from './jaarkalender/jaarkalender.component';
import { SteluwvraagComponent } from './steluwvraag/steluwvraag.component';
import { SteunonsComponent } from './steunons/steunons.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { VragenformulierComponent } from './vragenformulier/vragenformulier.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatCardModule} from '@angular/material/card'
import { MatSelectModule} from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { DocentenWidgetComponent } from './docenten-widget/docenten-widget.component';
import { WachtlijstdialogComponent } from './wachtlijstdialog/wachtlijstdialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IdentiteitComponent,
    BestuurComponent,
    OrganisatieComponent,
    OpleidingenComponent,
    DocentenComponent,
    StudentenComponent,
    RegelsenafsprakenComponent,
    PraktischComponent,
    JaarkalenderComponent,
    SteluwvraagComponent,
    SteunonsComponent,
    VragenformulierComponent,
    DocentenWidgetComponent,
    WachtlijstdialogComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    AngularFireModule.initializeApp(environment.config),
    AngularFirestoreModule,
    MatExpansionModule,
    MatSidenavModule,
    MatIconModule,
    MatTabsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
