import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  titel: string;
  @ViewChild('drawer') drawer: MatDrawer;

  constructor(private fs: AngularFirestore, private router: Router) {
    this.fs.collection('home').valueChanges().subscribe(
      data => {
        this.titel = data[0]['titel'];
      }
    )
  }

  openPortal(){
    window.open("https://studenten.alhidajah.nl/");
  }

  
  goToHome(){
    this.router.navigateByUrl('');
  }

  navigate(route: string){
    this.router.navigateByUrl(route).then( res => {
      this.drawer.toggle();
    });
  }
  title = 'alhidajah-site';
}
