<div class="banner-box">
    <img src="../../assets/steunonsbanner.jpg" alt="">
    <div class="titel-box">STEUN ONS</div>
</div>
<div class="intro-tekst-body">
    <p *ngFor="let t of paginaData?.introTekst"> {{t}}</p>
    <button color="primary" (click)="openUrl()" class="donate-button" mat-raised-button>Doneer eenmalig</button>
</div>
<div clas="steun-body">
    <div class="contact-opnemen">
        <div class="contact-info">
            <p>
                <img class="image2" src="../../assets/steunons.jpg" alt="steun ons">
            </p>
        </div>
        <div class="contact-formulier">
            <div [formGroup]="steunForm" class="box">

                <mat-form-field>
                    <mat-label>Naam</mat-label>
                    <input matInput formControlName="naam" class="naam" type="text" required/>
                    <mat-error>Naam is een verplicht veld.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Straat en huisnummer</mat-label>
                    <input matInput formControlName="adres" class="naam" type="text" required/>
                    <mat-error>Adres is een verplicht veld.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Postcode</mat-label>
                    <input matInput formControlName="postcode" class="naam" type="text" required/>
                    <mat-error>Ongeldige postcode.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Woonplaats</mat-label>
                    <input matInput formControlName="woonplaats" class="naam" type="text" required/>
                    <mat-error>Woonplaats is een verplicht veld.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Geboortedatum</mat-label>
                    <input matInput formControlName="geboorte" class="naam" type="date" placeholder="DD-MM-YYYY" required/>
                    <mat-error>Geboortedatum is verplicht. (DD-MM-YYYY)</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Uw IBAN</mat-label>
                    <input matInput formControlName="bank" class="naam" type="text" required/>
                    <mat-error>Ongeldige IBAN. Voer IBAN in zonder spaties!</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>E-mail</mat-label>
                    <input matInput formControlName="email" class="naam" type="text" required/>
                    <mat-error>U heeft een incorrecte email-adres ingevoerd</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Telefoon</mat-label>
                    <input matInput formControlName="telefoon" class="naam" type="text"
                        placeholder="06..." required/>
                    <mat-error>Dit is geen geldig telefoonnummer.</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Periode</mat-label>
                    <mat-select formControlName="periode" required>
                        <mat-option value="maandelijks">Maandelijks</mat-option>
                        <mat-option value="jaarlijks">Jaarlijks</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <input matInput formControlName="bedrag" class="naam" type="number" placeholder="Bedrag" />
                    <mat-error>Dit is geen geldig bedrag.</mat-error>
                </mat-form-field>

                <button [disabled]="steunForm.invalid" (click)="slaOp()" class="verzend-button">Verzenden</button>

            </div>
        </div>


        <p class="steun-dua">{{paginaData?.dua}}</p>
    </div>

</div>