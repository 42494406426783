import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-docenten',
  templateUrl: './docenten.component.html',
  styleUrls: ['./docenten.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocentenComponent implements OnInit {

  docenten: any;
  constructor(private fs: AngularFirestore) { }

  ngOnInit(): void {
    this.getBestuurInfo();
  }

  getBestuurInfo(){
    this.fs.collection("docenten").doc('docentendata').valueChanges().subscribe( data => {
      this.docenten = data['docenten'];
    });
  }
}
