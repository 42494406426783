import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-steluwvraag',
  templateUrl: './steluwvraag.component.html',
  styleUrls: ['./steluwvraag.component.scss']
})
export class SteluwvraagComponent implements OnInit {

  constructor(private fs: AngularFirestore) { }

  ngOnInit(): void { }

}
