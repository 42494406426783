<div class="header">
  <img  (click)="goToHome()" class="logo" src="../assets/logovector.png" alt="">
  <div routerLink="" class="title-box">
      <p class="sub-title">اطلبوا العلم من المهد إلى اللحد</p>
  </div>

  <div class="toolbar">
      <div class="buttons-box">
          <button routerLink="" mat-button>Home</button>

          <button mat-button [matMenuTriggerFor]="alHidajahMenu">Over ons</button>
          <mat-menu [hasBackdrop]="true" #alHidajahMenu="matMenu">
            <button mat-menu-item routerLink="/identiteit">Identiteit</button>
            <button mat-menu-item routerLink="/bestuur">Bestuur</button>
            <button mat-menu-item routerLink="/organisatie">Organisatie</button>
          </mat-menu>

          <button mat-button [matMenuTriggerFor]="onderwijsMenu">Onderwijs</button>
          <mat-menu #onderwijsMenu="matMenu">
            <button mat-menu-item routerLink="/opleidingen">Opleidingen</button>
            <button mat-menu-item routerLink="/docenten">Docenten</button>
            <button mat-menu-item routerLink="/studenten">Studenten</button>
            <button mat-menu-item routerLink="/regels-en-afspraken">Regels & Afspraken</button>
          </mat-menu>

          <!-- <button mat-button [matMenuTriggerFor]="algemeenMenu">Contact</button>
          <mat-menu #algemeenMenu="matMenu"> -->
            <!-- <button mat-menu-item routerLink="/praktische-zaken">Praktische zaken</button> -->
            <!-- <button mat-menu-item routerLink="/jaarkalender">Jaarkalender</button> -->
            <!-- <button mat-menu-item routerLink="/stel-uw-vraag">Stel uw vraag</button>
            <button mat-menu-item routerLink="/steun-ons">Steun ons</button>
          </mat-menu> -->

          <button mat-button  routerLink="/stel-uw-vraag">Contact</button>
          <button mat-button  routerLink="/steun-ons">Steun ons</button>
      </div>
      <button (click)="openPortal()" class="log-in-button" mat-button>
        Log in
        <mat-icon>arrow_forward</mat-icon>
      </button>
  </div>
</div>
<div class="mobile-header">
  <button (click)="drawer.toggle()" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
</div>
<mat-drawer-container hasBackdrop="true">
  <mat-drawer id="drawer" #drawer mode="push">
    <div class="color-box"></div>
    <button mat-button (click)="navigate('')">Home</button>
    <button mat-button [matMenuTriggerFor]="alHidajahMobileMenu">Over ons</button>
    <mat-menu [hasBackdrop]="true" #alHidajahMobileMenu="matMenu">
      <button mat-menu-item (click)="navigate('/identiteit')">Identiteit</button>
      <button mat-menu-item (click)="navigate('/bestuur')">Bestuur</button>
      <button mat-menu-item (click)="navigate('/organisatie')">Organisatie</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="onderwijsMobileMenu">Onderwijs</button>
    <mat-menu #onderwijsMobileMenu="matMenu">
      <button mat-menu-item (click)="navigate('/opleidingen')">Opleidingen</button>
      <button mat-menu-item (click)="navigate('/docenten')">Docenten</button>
      <button mat-menu-item (click)="navigate('/studenten')">Studenten</button>
      <button mat-menu-item (click)="navigate('/regels-en-afspraken')">Regels & Afspraken</button>
    </mat-menu>

    <!-- <button mat-button [matMenuTriggerFor]="algemeenMobileMenu">Contact</button>
    <mat-menu #algemeenMobileMenu="matMenu"> -->
      <!-- <button mat-menu-item (click)="navigate('/praktische-zaken')">Praktische zaken</button> -->
      <!-- <button mat-menu-item (click)="navigate('/jaarkalender')">Jaarkalender</button> -->

    <!-- </mat-menu> -->
    <button mat-menu-item (click)="navigate('/stel-uw-vraag')">Contact</button>
    <button mat-menu-item (click)="navigate('/steun-ons')">Steun ons</button>
    <button (click)="openPortal()" mat-button>
      Log in
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<footer>
  <div class="footer-div">
    <span style="margin-left: 10px;">KvK: 82250928</span>
    <span>© 2021 door Alhidajah. Alle rechten voorbehouden</span>
    <span style="margin-right: 10px;">RSIN: 862395124</span>
  </div>
</footer>