<div class="banner-box">
    <img src="../../assets/bestuur.jpg" alt="">
    <div class="titel-box">HET BESTUUR</div>
</div>
<div class="bestuur-content">
    <div *ngFor="let b of bestuursleden" class="bestuur-item">
        <img *ngIf="b.afbeelding" [src]="b.afbeelding" alt="">
        <img *ngIf="!b.afbeelding" src="../../assets/person_icon.png" alt="">
        <p class="titel">{{b.titel}}</p>
        <div class="line"></div>
        <p class="naam">{{b.naam}}</p>
    </div>
</div>