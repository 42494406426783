
<div class="banner-box">
    <img src="../../assets/praktische-zaken1.jpg" alt="" width="300" height="100">
</div>
<div class="prakitsch">
    <p class="prakitsch-titel">PRAKTISCHE ZAKEN</p>
</div>
<div clas="prakitsch-body">
    <p class="prakitsch-body">Hier vindt u algemene informatie zoals de bereikbaarheid van Alhidajah, de jaarkalender, u kunt ons een vraag stellen en u kunt donateur worden van Alhidajah.</p>
    <div class="prakitsch-body">
    <div class="praktisch-body">
        Wij zijn als volgt te bereiken: <br>
    </div>
    <br>
    <div class="tabel">
        <div class="prakitsch-tabel" >
            <br>
            <table class="tabel"> 
                <tr>
                    <td>Voorzitter van het bestuur</td>
                    <td><a href="mailto:maulanatahier@alhidajah.nl">maulanatahier@alhidajah.nl</a></td>
                </tr>
                <tr>
                    <td>Studentenadministratie</td>  
                    <td><a href="mailto:studentenadmin@alhidajah.nl">studentenadmin@alhidajah.nl</a></td>  
                </tr>
                <tr>
                    <td> Algemene zaken</td>
                    <td>  <a href="mailto:info@alhidajah.nl">info@alhidajah.nl</a></td>  
                </tr>
                <tr>
                    <td> Beheer van de website</td> 
                    <td>  <a href="mailto:webbeheer@alhidajah.nl">webbeheer@alhidajah.nl</a></td>  
                </tr>
        </table><br>
            </div>
        </div>
        <br>
    </div>
    
</div>
