import { SteunonsComponent } from './steunons/steunons.component';
import { SteluwvraagComponent } from './steluwvraag/steluwvraag.component';
import { JaarkalenderComponent } from './jaarkalender/jaarkalender.component';
import { PraktischComponent } from './praktisch/praktisch.component';
import { RegelsenafsprakenComponent } from './regelsenafspraken/regelsenafspraken.component';
import { StudentenComponent } from './studenten/studenten.component';
import { DocentenComponent } from './docenten/docenten.component';
import { OpleidingenComponent } from './opleidingen/opleidingen.component';
import { OrganisatieComponent } from './organisatie/organisatie.component';
import { BestuurComponent } from './bestuur/bestuur.component';
import { IdentiteitComponent } from './identiteit/identiteit.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'identiteit',
    component: IdentiteitComponent
  },
  {
    path: 'bestuur',
    component: BestuurComponent
  },
  {
    path: 'organisatie',
    component: OrganisatieComponent
  },
  {
    path: 'opleidingen',
    component: OpleidingenComponent
  },
  {
    path: 'docenten',
    component: DocentenComponent
  },
  {
    path: 'studenten',
    component: StudentenComponent
  },
  {
    path: 'regels-en-afspraken',
    component: RegelsenafsprakenComponent
  },
  {
    path: 'stel-uw-vraag',
    component: SteluwvraagComponent
  },
  {
    path: 'steun-ons',
    component: SteunonsComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled', useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
