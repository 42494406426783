import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { v4 as  uuidv4} from 'uuid';


@Component({
  selector: 'app-steunons',
  templateUrl: './steunons.component.html',
  styleUrls: ['./steunons.component.scss'],

})
export class SteunonsComponent implements OnInit {
  
  steunForm: FormGroup;
  paginaData: any;

  constructor(private fs: AngularFirestore, private snackbar: MatSnackBar) {
    this.steunForm = new FormGroup({
      naam: new FormControl("",[Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      adres: new FormControl("",[Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      postcode: new FormControl("",[Validators.required, Validators.minLength(6), Validators.maxLength(7), Validators.pattern(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)]),
      woonplaats: new FormControl("",[Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      geboorte: new FormControl("",[Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      bank: new FormControl("",[Validators.required, Validators.minLength(2), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/i)]),
      email: new FormControl('',[Validators.required, Validators.minLength(5), Validators.email, Validators.maxLength(100)]),
      telefoon: new FormControl('',[Validators.minLength(7), Validators.required]),
      periode: new FormControl("",[Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      bedrag: new FormControl('',[Validators.required, Validators.min(5)])
    });
   }

  ngOnInit(): void {
    this.getData();
  }

  openUrl(){
    window.open(this.paginaData.betaalurl,"_blank")
  }

  getData(){
    this.fs.collection('steunons').doc('1').valueChanges()
    .subscribe( res => {
      this.paginaData = res;
    });
  }

  openSnackbar(message: string){
    return this.snackbar.open(message, null , {
      panelClass: 'snack',
      duration: 5000
    });
  }



  slaOp(){
    const dataToSend = {

      naam: this.steunForm.controls.naam.value,
      adres: this.steunForm.controls.adres.value,
      postcode: this.steunForm.controls.postcode.value,
      woonplaats: this.steunForm.controls.woonplaats.value,
      geboorteDatum: this.steunForm.controls.geboorte.value,
      bankrekening: this.steunForm.controls.bank.value,
      email: this.steunForm.controls.email.value,
      telefoon: this.steunForm.controls.telefoon.value,
      periode: this.steunForm.controls.periode.value,
      bedrag: this.steunForm.controls.bedrag.value,
      aanvraagDatum: new Date(),
      registratieNr: `D${uuidv4().substring(0,6)}`
    };

      this.fs.collection("donateur-aanvragen").add(dataToSend)
      .then( data => {
        this.openSnackbar("Succesvol aangevraagd! Wij nemen contact met u op.");
        this.steunForm.reset();
      }).catch( error => {
        this.openSnackbar("Aanvraag niet gelukt! Neem contact op met ons.");
      });
  }

  send(){
    if (this.steunForm.valid) {
        this.fs.collection('donateur-aanvragen').add(this.steunForm.value)
        .then( result => {
          this.openSnackbar("Uw aanvraag is succesvol verstuurd!");
          this.steunForm.reset();
        }).catch( error => {
          this.openSnackbar("Woops! Er is iets misgegaan! Heeft u wel internet?")
        });
      } else {
        return false;
      }
    }
  }

